import { VARIABLES } from "../strings/variables";
import axios from "axios";
import { createApi } from "@reduxjs/toolkit/query/react";

/**
 * Base factory function for using axios with Redux toolskit's RKT Query data fetching API
 *
 * @returns a base query which can take multiple parameters such as "withCredentials"
 */
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params, headers, withCredentials = true }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        withCredentials,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

/**
 * Any endpoint communcating with Univid API should be injected to this
 */
const unividApi = createApi({
  reducerPath: "unividApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${VARIABLES.apiUrl}`,
  }),
  endpoints: () => ({}),
});

export { unividApi };
