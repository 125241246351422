import { configureStore } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import userReducer from "./reducers/userReducer";
import themeReducer from "./slices/themeSlice";
import sessionReducer from "./slices/sessionSlice";
import meetingReducer from "./slices/meetingSlice";
import cookieReducer from "./slices/cookieSlice";
import browserReducer from "./slices/browserSlice";
import callToActionReducer from "./slices/callToActionSlice";
import organizationReducer from "./slices/organizationSlice";
import { unividApi } from "../services/base";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const store = configureStore({
  reducer: {
    user: userReducer,
    theme: themeReducer,
    session: sessionReducer,
    meetingInfo: meetingReducer,
    cookie: cookieReducer,
    browserInfo: browserReducer,
    callToAction: callToActionReducer,
    organization: organizationReducer,
    [unividApi.reducerPath]: unividApi.reducer,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat([sentryReduxEnhancer]);
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["session/setSessionInformation"],
        ignoredPaths: ["session.description"],
      },
    }).concat(unividApi.middleware);
  },
});

// in the future add this: setupListeners(store.dispatch), that way we can refetch data when browser is refocused etc (https://redux-toolkit.js.org/rtk-query/overview)

export default store;
