import styles from "./UnividButton.module.css";
import PropTypes from "prop-types";
import classNames from "classnames";

const UnividButton = ({
  children,
  onClick,
  color,
  disabled = false,
  size = "medium",
  padding = "normal",
  fullWidth = false,
  submit = false,
  inverted = false,
  noHoverAnimation = false,
  margin,
}) => {
  const className = classNames(styles.Button, {
    [styles.ButtonInverted]: inverted,
    [styles.SmallSize]: size === "small",
    [styles.Fat]: padding === "fat",
    [styles.Slim]: padding === "slim",
    [styles.FullWidth]: fullWidth,
    [styles.HoverAnimation]: !noHoverAnimation,
  });

  const type = submit ? "submit" : "button";

  const styleObject = {};
  if (!disabled) {
    if (color) {
      if (inverted) {
        styleObject.color = color;
      } else {
        styleObject.backgroundColor = color;
      }
    }
    if (margin) {
      styleObject.margin = margin;
    }
  }

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
      style={styleObject}
    >
      {children}
    </button>
  );
};

export default UnividButton;

UnividButton.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  submit: PropTypes.bool,
  inverted: PropTypes.bool,
  required: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  padding: PropTypes.oneOf(["normal", "fat", "slim"]),
  noHoverAnimation: PropTypes.bool,
};
