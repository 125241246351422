import PropTypes from "prop-types";
import styles from "./AlertMessage.module.css";

const AlertMessage = ({ errorMessage, color }) => (
  <div className={styles.Alert} style={color ? { color: color } : {}}>
    <span style={{ fontWeight: 500 }}>Error: </span>
    {errorMessage}
  </div>
);

AlertMessage.propTypes = {
  errorMessage: PropTypes.string,
  color: PropTypes.string,
};

export default AlertMessage;
